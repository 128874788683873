/*
* @Author: babysoft08
* @Date:   2025-02-17 12:29:03
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-02-21 18:03:36
*/
export const downloadCategories = [
	{
		name: 'Catalogs & Brochures',
		ids: '2,10',
		permalink: 'catalogs-brochures',
		component: 'CatalogBrochure'
	},
	{
		name: 'Instruction Guides & Manuals',
		ids: '1',
		permalink: 'instruction-guides-manuals',
		component: 'InstructionGuides'
	},
	{
		name: 'Policies & Forms',
		ids: '3',
		permalink: 'policies-forms',
		component: 'PoliciesForms'
	},
	{
		name: 'Certifications',
		ids: '11',
		permalink: 'certifications',
		component: 'Certifications'
	},
	{
		name: 'En Espanol',
		ids: '4',
		permalink: 'espanol',
		component: 'Espanol'
	}
];