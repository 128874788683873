import productCastIron from '../images/products-img/cast-iron.png';
import productAluminum from '../images/products-img/aluminum.png';
import productAluminumCobra3Phase from '../images/products-img/aluminum-cobra-3-phase.png';
import productAluminumCobraSinglePhase from '../images/products-img/aluminum-cobra-single-phase.png';
import productExplosionProof from '../images/products-img/explosion-proof.png';
import productAerator from '../images/products-img/aerator.png';
import productPhaseConverter from '../images/products-img/phase-converter.png';
import productStainlessSteel from '../images/products-img/stainless-steel.png';
import productMetricIec from '../images/products-img/metric-iec.png';
import productMetricIecSingle from '../images/products-img/metric-iec-single.png';
import productFarmDutySingle from '../images/products-img/farm-duty-single.png';
import productIeee841 from '../images/products-img/ieee-841.png';
import productOpenDrip from '../images/products-img/open-drip.png';
import productCrusherDuty from '../images/products-img/crusher-duty.png';
import productDcMotor from '../images/products-img/dc-motor.png';
import productCoolingTower from '../images/products-img/cooling-tower.png';
import productWashdown from '../images/products-img/washdown.png';
import productOilPump from '../images/products-img/oil-pump.png';
import productBreakMotors from '../images/products-img/brake-motors.png';
import productJmJpPump from '../images/products-img/jm-jp-pump.png';
import productTopDrive from '../images/products-img/top-drive.jpg';
import productGearReducer from '../images/products-img/gear-reducer.jpg';
import xr3Icon from '../images/XR3-img.png';


export const productData = [
  {
    "name": "Cast Iron",
    "details": [
      "HP: 1–500",
      "Frame: 143T– 449T",
      "Pole: 2,4,6",
      "Volt: 230/460V",
      "RPM: 3600/1800/1200",
      "Body: T, TC"
    ],
    "image": productCastIron,
    "link": "/products/three-phase-cast-iron",
    "is_featured":1,
    "is_show_xr":1
  },
  {
    "name": "Aluminum",
    "details": [
      "HP: 1–30",
      "Frame: 143T–286T",
      "Pole: 2,4,6",
      "Volt: 230/460V",
      "RPM: 3600/1800/1200",
      "Encl: (TEFC) IP 55"
    ],
    "image": productAluminum,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":0
  },
  {
    "name": "Aluminum Cobra 3-Phase",
    "details": [
      "HP: 0.33 - 3",
      "Frame: 56C",
      "Pole: 2,4",
      "Volt: 230/460V",
      "RPM: 3600/1800"
    ],
    "image": productAluminumCobra3Phase,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":1
  },
  {
    "name": "Aluminum Cobra Single Phase",
    "details": [
      "HP: 0.33 - 10",
      "Frame: 56C–215T",
      "Pole: 2,4",
      "Volt: 115/208-230",
      "RPM: 3600/1800"
    ],
    "image": productAluminumCobraSinglePhase,
    "link": "/products/cobra-single-phase-aluminum",
    "is_featured":1,
    "is_show_xr":0
  },
  {
    "name": "Explosion Proof",
    "details": [
      "HP: 1 - 250",
      "Frame: 143T - 449T",
      "Pole: 2,4,6",
      "Volt: 230/460V",
      "RPM: 1200/1800/3600"
    ],
    "image": productExplosionProof,
    "link": "/products/explosion-proof",
    "is_featured":1,
    "is_show_xr":0
  },
  {
    "name": "Aerator",
    "details": [
      "HP: 3-75",
      "Pole: 4,6",
      "Volt: 230/460V & 575V",
      "RPM: 1800/1200"
    ],
    "image": productAerator,
    "link": "/products/aerator",
    "is_show_xr":0
  },
  {
    "name": "Phase Converter",
    "details": [
      "HP: 3–75",
      "Frame: 182–365",
      "Pole: 4",
      "Volt: 230/460V",
      "RPM: 1800",
      "Body: T"
    ],
    "image": productPhaseConverter,
    "link": "/products/phase-converter-motor",
    "is_featured":0,
    "is_show_xr":0
  },
  {
      "name": "Washdown",
      "details": [
        "HP: .33–15",
        "Frame: 56C–215T",
        "Pole: 2,4,6",
        "Volt: 230/460V",
        "RPM: 3600/1800/1200",
        "Body: T"
      ],
      "image": productWashdown,
      "link": "https://techtopind.com/products/washdown"
    },
    {
      "name": "Oil Pump",
      "details": [
        "HP: 3–125",
        "Frame: 213T–445T",
        "Pole: 6",
        "Volt: 230/460/796V",
        "RPM: 1200",
        "Body: T"
      ],
      "image": productOilPump,
      "link": "https://techtopind.com/products/oil-pump"
    },
    {
      "name": "Brake Motors",
      "details": [
        "HP: 1/3 - 15HP",
        "Frame: 56-215T, 63 - 112",
        "Volt: 230/ 460V",
        "RPM: 1800/3600"
      ],
      "image": productBreakMotors,
      "link": "https://techtopind.com/products/brake-motor-nema"
    },
    {
      "name": "JM/JP Pump",
      "details": [
        "HP: 1/3–75",
        "Frame: 56J–365JP",
        "Pole: 2,4",
        "Volt: 230/460V",
        "RPM: 3600/1800",
        "Body: J/JM/JP"
      ],
      "image": productJmJpPump,
      "link": "https://techtopind.com/products/premium-jm-jp"
    },
  {
    "name": "Stainless Steel",
    "details": [
      "HP: 1/2– 3",
      "Frame: 56C ,56HC,143TC,145TC",
      "Pole: 2,4",
      "Volt: 230/460V",
      "RPM: 1800/3600",
      "Round and Footed"
    ],
    "image": productStainlessSteel,
    "link": "/products/stainless-steel",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Metric - IEC",
    "details": [
      "HP: .12–150",
      "kW: 0.09–110",
      "Frame: 56–280",
      "Pole: 2,4,6,8",
      "Volt: 230/460V",
      "RPM: 1200/1800/36"
    ],
    "image": productMetricIec,
    "link": "/products/metric-iec",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Metric - IEC Single Phase",
    "details": [
      "HP: 0.12–3",
      "kW: 0.09–2.2",
      "Frame: 56–100",
      "Pole: 4",
      "Volt: 115 & 230V",
      "RPM: 1800"
    ],
    "image": productMetricIecSingle,
    "link": "/products/metric-iec-single-phase",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Farm Duty Single Phase",
    "details": [
      "HP: 1/3–10",
      "Frame: 56–215T",
      "Pole: 4",
      "Volt: 115/208–230 (<145T) 208–230 (>182T)",
      "RPM: 1800 (60 Hz)",
      "Body: T/TC/RND"
    ],
    "image": productFarmDutySingle,
    "link": "/products/farm-duty",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "IEEE 841",
    "details": [
      "HP: 1–300",
      "Frame: 143T–449T",
      "Pole: 2,4,6",
      "Volt: 460V",
      "RPM: 3600/1800/1200",
      "Body: T–Frame"
    ],
    "image": productIeee841,
    "link": "/products/ieee-841",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Open Drip",
    "details": [
      "HP: 1–150",
      "Frame: 143T–445T",
      "Pole: 2,4",
      "Volt: 230/460V",
      "RPM: 1800/3600",
      "BODY: T"
    ],
    "image": productOpenDrip,
    "link": "/products/three-phase-open-enclosure",
    "is_featured":0,
    "is_show_xr":1
  },
  {
    "name": "Crusher Duty",
    "details": [
      "HP: 75–300",
      "Frame: 365T–449T",
      "Pole: 4,6",
      "Volt: 460V",
      "RPM: 1800/1200",
      "Body: T"
    ],
    "image": productCrusherDuty,
    "link": "/products/crusher-duty",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "DC Motor",
    "details": [
      "Designed for use on SCR and PWM type direct current adjustable speed drives and on across-the-line DC controls. Ideal applications include conveyors, turntables, packaging or anywhere adjustable speed and constant torque is required.",
    ],
    "image": productDcMotor,
    "link": "/products/pm-dc-motor",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Cooling Tower",
    "details": [
      "HP: 5–50",
      "Frame: 184T - 326T",
      "Pole: 4",
      "Volt: 230/460",
      "RPM: 1800",
      "Body: T"
    ],
    "image": productCoolingTower,
    "link": "/products/cooling-tower-xr3",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Gear Reducers",
    "details": [],
    "image": productGearReducer,
    "link": "https://online.flippingbook.com/view/736684496/",
    "is_featured":0,
    "is_show_xr":0
  },
  {
    "name": "Top Drive VFD",
    "details": [],
    "image": productTopDrive,
    "link": "https://techtopind.com/files/download-item/pdf_documents/20230126202320.pdf",
    "is_featured":0,
    "is_show_xr":0
  }
];