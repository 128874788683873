import React, { Component } from 'react';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import { urlPath } from './constants.js'

class App extends Component {
    componentDidMount() {
        const loadStyles = async () => {
            //console.log('path - ' +urlPath);
            if (urlPath === 'user/my-account' || urlPath === 'user/order-history' || urlPath === 'user/open-orders') {
                await import('./css/user/custom.css');
                await import('./css/user/responsive.css');
            } else if(urlPath === '' || urlPath === '/' || urlPath === 'products' || urlPath === 'products/' || urlPath.startsWith('downloads/')) {
                await import ('./css/new-custom.css');
                await import ('./css/new-responsive.css');
            } else {
                await import('./css/custom.css');
                await import('./css/responsive.css');
            }
            await import('./css/footer.css');
        };
        loadStyles();
    }

    render() {
        return (
            <div className="App">
                <Header />
                <Main />
                <Footer />
            </div>
        );
    }
}

export default App;
