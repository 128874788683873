/*
* @Author: babysoft08
* @Date:   2025-02-17 12:44:08
* @Last Modified by:   abhishek714
* @Last Modified time: 2025-02-19 11:50:50
*/
import React from 'react';
import { Link } from 'react-router-dom';
import DownloadService from '../../services/DownloadService/DownloadService';
import { downloadCategories } from '../../helper/DownloadCategories';
import GearSpinner from '../../components/GearSpinner/GearSpinner';
import { MobileSidebar, DesktopSidebar } from '../../components/Downloads/sidebar';

class Downloads extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ComponentToRender: null,
			permalink: '',
			category: {},
			isLoading: true, // Added loading state
		};
	}

	componentDidMount() {
		const permalink = this.props.match?.params?.permalink || 'catalogs-brochures';
		const category = downloadCategories.find(item => item.permalink === permalink);
		this.setState({category : category});

		if (category && category.component) {
			import(`../../components/Downloads/${category.permalink}`)
				.then(module => {
					this.setState({ 
						ComponentToRender: module.default, 
						permalink, 
						isLoading: false // Stop loading when component is found
					});
				})
				.catch(() => {
					this.setState({ isLoading: false, ComponentToRender: null });
				});
		} else {
			this.setState({ isLoading: false, ComponentToRender: null });
		}
	}
	
	render() {
		const { ComponentToRender, permalink, isLoading, category } = this.state;
		if (isLoading) {
			return (
				<div className="d-flex justify-content-center align-items-center">
					
				</div>
			)
		}

		return (
			<React.Fragment>
				<main className="techtop-middle-div" id="techtop-downloads-and-catalogs-section">
					<section className="pt-2">	
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="default-title">
										<h2 className="txt-color2">{category && category.name}</h2>
									</div>
														
								</div>
							</div>
						</div>
					</section>
					<div className="container overflow-hidden pb-2">
						<div className="row vh-100 overflow-auto">
							<MobileSidebar params={category && category.permalink}/>
							<div className="col-12 col-sm-9 col-xl-9 d-flex flex-column h-sm-100">
								<div className="row overflow-auto">
									{ComponentToRender ? <ComponentToRender permalink={permalink} /> : <h1>404 - Page Not Found</h1>}
								</div>
							</div>
							<DesktopSidebar params={category && category.permalink}/>
						</div>
					</div>
				</main>
			</React.Fragment>
		);
	}
}

export default Downloads;