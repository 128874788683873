/*
* @Author: abhi
* @Date:   2024-04-20 19:56:55
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-03-25 11:57:07
*/
import { MAIL_FORMAT, ucwords } from './constants';

export const Validation = (data, validate_fields) => {
    let errors = {};
    const restrictedChars = [';']; // Add more characters if needed

    if (validate_fields && validate_fields.length > 0) {
        validate_fields.forEach((value) => {
            if (!data[value]) {
                errors[value] = ucwords(value.replace(/_/g, " ")) + ' is required.';
            } else {
                // Ensure value is a string before checking for restricted characters
                if (typeof data[value] === 'string' && restrictedChars.some(char => data[value].includes(char))) {
                    errors[value] = ucwords(value.replace(/_/g, " ")) + ' contains invalid characters.';
                }

                // Email validation
                if (value === 'email' && !MAIL_FORMAT.test(data[value])) {
                    errors[value] = ucwords(value.replace("_", " ")) + ' is invalid.';
                }

                // Confirm password validation
                if (value === 'confirm_password' && data[value] !== data['password']) {
                    errors[value] = 'Please enter the same value again.';
                }
            }
        });
    }
    return errors;
};