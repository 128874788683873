/*
* @Author: babysoft08
* @Date:   2025-02-17 14:09:03
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-02-18 13:56:08
*/
import React, { Component } from 'react';
import barIcon from '../../images/bar-icon.png';
import { downloadCategories } from '../../helper/DownloadCategories';
export class MobileSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			permalink: props.params,
		};
	}

	render() {
		return(
			<React.Fragment>
				<div className="col-12 col-sm-3 col-xl-3 px-sm-2 px-0 bg-dark d-flex sticky-top mobile-view-sidebar">
					<div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 py-2 text-white">
						<a className="sidebar-bar-icon" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
							<img src={barIcon} alt="bar-icon"/>
						</a>
						<div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
							<div className="offcanvas-header">
								<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
							</div>
							<div className="offcanvas-body">
                            {Array.isArray(downloadCategories) && downloadCategories.length > 0 ? (
                                <ul className="navbar-nav" id="menu2">
                                    {downloadCategories.map((item, index) => (
                                        <li key={index} className="nav-item">
                                            <a href={`/downloads/${item.permalink}`} className={`nav-link ${this.props.params === item.permalink ? 'active' : ''}`}>
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <React.Fragment>
                                </React.Fragment> 
                            )}
                        </div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export class DesktopSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}

	render() {
		return(
			<React.Fragment>
				<div className="col-12 col-sm-3 col-xl-3 px-sm-2 px-0 bg-dark d-flex sticky-top desktop-view-sidebar">
					<div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 py-2 text-white">
						{Array.isArray(downloadCategories) && downloadCategories.length > 0 ? (
                            <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start" id="menu">
                                {downloadCategories.map((item, index) => (
                                    <li key={index} className="nav-item">
                                        <a href={`/downloads/${item.permalink}`} className={`nav-link ${this.props.params === item.permalink ? 'active' : ''}`}>
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <React.Fragment>
                            </React.Fragment> 
                        )}
					</div>
				</div>
			</React.Fragment>
		)
	}
}