/*
* @Author: Abhi Bhatt
* @Date:   2020-06-16 13:13:59
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-02-18 19:38:19
*/
import axios from 'axios';
import {API_URL} from '../../constants.js';

class DownloadService {
	async getDownload(ids=null) {
		let url = `${API_URL}/get-downloads`;
	    // Append ids to the URL if provided
	    if (ids !== null) {
	        url += `/${ids}`;
	    }
		const headers = {
			'Content-Type':'multipart/form-data',
			'Access-Control-Allow-Origin':'*'

		}
		let formData = new FormData();
		return axios({
			url:url,
			method: 'get',
			crossDomain:true,
			data: formData,
			config:{headers:headers},
			//headers: {'Access-Control-Allow-Origin': '*'}
		}).then(function(response) {
			return response;
		}).catch(function(response) {
			return response;
		});
	}

	async shareDownloadItem(email, download_item_id) {
		const url = `${API_URL}/share-download-item/`;
		const headers = {
			'Content-Type': 'application/json',
		};
	
		return axios({
			url: url,
			method: 'post',
			data: JSON.stringify({ email, download_item_id }),
			headers: headers,
		})
		.then(function (response) {
			return response.data;
		})
		.catch(function (error) {
			return error.response ? error.response.data : { error: 'Unknown error' };
		});
	}
}

export default DownloadService;